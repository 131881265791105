.alert-old {
  position: relative;
  z-index: 1;
  padding: 16px;
  opacity: 1;
  font-size: 16px;

  &.alert-with-image {
    line-height: 24px;

    img {
      max-width: 24px;
      position: relative;
      vertical-align: bottom;
      margin-right: 5px;
    }
  }

  &.alert-danger {
    border-top: 1px solid $reserved-danger;
    border-bottom: 1px solid $reserved-danger;
    background-color: #fcf2f2;
    color: $reserved-danger;
  }

  &.alert-success {
    color: $reserved-success;
    background-color: #f2f9f2;
    border-top: 1px solid $reserved-success;
    border-bottom: 1px solid $reserved-success;
  }

  span {
    font-weight: 700;
  }
}
