// This scss file contains styling for html in our tenant config files

.eula-table {
  @apply mxc-my-2;
}

.eula-list {
  list-style: lower-alpha;
  @apply mxc-pl-3;
}
