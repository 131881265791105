/* You can add global styles to this file, and also import other style files */

@tailwind base;

@import 'variables';

@import '_alerts';
@import '_config';
@import '_forms';
@import '_icons';
@import '_layouts';
@import '_typography';
@import '_queries';
@import '_legacy';

:root {
  --main-layout-content-max-width: 1536px;
}

html,
body {
  height: 100%;
}

// hero-button is a button we use with a mat-button / mat-stroked-button
// this style is not in Material / GEL
.hero-button {
  color: $primary-hero !important;

  &.hero-button--outline {
    border-color: $primary-hero !important;
  }
}

/*
to fix MERSRV-5548 - when background content change height, it is not scrolling back to the top, solution found here
https://stackoverflow.com/questions/47782890/angular-material-dialog-component-hides-all-my-website-components
*/
.cdk-global-scrollblock {
  position: initial !important;
}

// Added to ensure the back to links have a standard rectangle on focus. Currently, it depends on
// other "taller" elements in the container.
.back-to-link-row-min-height {
  display: flex;
  min-height: 36px;
}

// permission
.permission-outline {
  outline: solid #33ff00 2px;
  position: relative;

  &.permission-outline-empty {
    height: 25px;
  }

  .permission-key {
    font-size: 12px;
    top: 0;
    right: 0;
    padding: 3px 5px;
  }

  .permission-check-passed {
    @extend .permission-key;
    background-color: #33ff0030;
  }

  .permission-check-failed {
    @extend .permission-key;
    background-color: #ff000030;
  }
}

.app-loading {
  visibility: hidden;
}

// visual indicator for invalid or empty tenant specific text
.invalid-text {
  color: red;
  border-color: red;
  border-style: double;
}

[mxc-label][required]::after {
  content: ' *';
  vertical-align: middle;
}

.centered-content {
  @apply mxc-max-w-[var(--main-layout-content-max-width)] mxc-mx-auto mxc-px-4 mxc-py-5;
}
