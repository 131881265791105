@import '_queries';

.mat-icon {
  min-width: 24px !important;
}

.mat-suffix-link {
  cursor: pointer;
}

mat-error {
  font-size: 14px;
}

mat-form-field {
  &.mat-form-field-appearance-outline {
    .mat-form-field-label {
      // To darken label
      color: $primary-neutral;
    }

    .mat-form-field-wrapper {
      .mat-form-field-outline-thick {
        // To not change border color on hover
        color: $primary-muted;
      }
    }
  }
}
