@import './_queries';
@import './_variables';
@import './functions/functions';

$height-without-header-and-footer--mobile: calc(100vh - 225px); // nav (60px) + footer (165px)
$height-without-header-and-footer: calc(100vh - 277px); // nav (112px) + footer (165px)
$height-without-header--mobile: calc(100vh - 165px); // footer (165px)
$height-without-header: calc(100vh - 165px); // footer (165px)

.main-content {
  min-height: $height-without-header-and-footer--mobile;
  overflow: auto;

  @include breakpoint-min(768px) {
    min-height: $height-without-header-and-footer;
  }

  &:focus {
    outline: none;
  }
}

.main-content--no-menu {
  min-height: $height-without-header--mobile;
  overflow: auto;

  @include breakpoint-min(768px) {
    min-height: $height-without-header;
  }
}

.main-content__row--wide,
.main-content__row--narrow {
  margin: 0 8vw;
}

.main-content__row--narrow {
  max-width: 500px;
}

.main-content__row--wide {
  max-width: var(--main-layout-content-max-width);
}

@media #{$mat-sm} {
  .main-content__row--narrow {
    margin: 0 auto;
  }
}

@media #{$mat-md} {
  .main-content__row--wide {
    margin: 0 auto;
  }
}

mat-card-header.mat-card-header {
  height: auto;
  text-align: center;
  padding: 0 !important;
  border-top-left-radius: 0.16px;
  border-top-right-radius: 0.16px;
  border: 0.5px solid $primary-border;

  a {
    text-decoration: none;
  }

  span {
    font-size: 14px;
    &:not(.mat-button-wrapper) {
      margin-left: 0.3em;
      padding-top: 20px;
      padding-bottom: 20px;
      font-size: 22px;
      color: $primary-neutral;
      font-weight: bold;
    }
  }

  button {
    margin: 1em 1em 1em auto;
    font-size: large;
    color: $primary-neutral;
    background-color: white;
    border-radius: 0.25em;
    padding-left: 1em;
    padding-right: 1em;
  }

  .mat-card-header-text {
    margin: 0;
  }
}

mat-card {
  padding: 0px;
  border-radius: 1em;
  border-top-left-radius: 1em;
  border-top-right-radius: 1em;
}

mat-card-content.mat-card-content {
  border: 0.5px solid #979797;
  border-top: none;
}

mat-card-content .search-field {
  width: 90%;
  margin-left: 4%;
}

mat-card-content .filterInput {
  line-height: 19px;
  margin-bottom: 3px;
}

mat-card-content mat-progress-spinner {
  margin-left: auto;
  margin-right: auto;
}

mat-card input {
  color: $primary-neutral;
}

@media #{$mat-sm} {
  mat-card-header.mat-card-header {
    button {
      font-size: initial;
    }
    span {
      &:not(.mat-button-wrapper) {
        margin-left: 1em;
        padding-top: 26px;
        padding-bottom: 26px;
        font-size: 22px;
      }
    }
  }
}
