// CORE VARIABLES

// Colors
$white: #ffffff;

// THEME VARIABLES

// Westpac pallete from https://gel.westpacgroup.com.au/GUI/WBC/colours/
// [DEPRECATED] Set tenant specific colors in the component library
$primary-hero: #621a4b;
$primary-neutral: #2d373e;
$primary-muted: #575f65;
$primary-border: #d7d2cb;

$reserved-success: #008000;
$reserved-danger: #c40000;
