// functions and mixins

@mixin breakpoint-min($point) {
  @media (min-width: $point) {
    @content;
  }
}

@mixin breakpoint-max($point) {
  @media (max-width: $point) {
    @content;
  }
}
